import React, {useContext, useEffect, useState} from 'react';
import MoneyFlowService from "../../../services/MoneyFlowService";
import './MoneyFlow.css';
import {periodDecrypt} from "../../../helpers/periodDecrypt";
import {Context} from "../../../index";
import {useNavigate} from "react-router-dom";

export interface IMoneyFlowItem {
  individual: string;
  artist: string;
  album: string;
  category: string;
  description: string;
  amount: string;
  is_taken_into_account_in_the_report: boolean;
  date_added: string;
  period: string;
  record_type: string;
  is_internal: boolean;
  upc: string;
}

const MoneyFlow = () => {
  const [moneyFlowItems, setMoneyFlowItems] = useState<IMoneyFlowItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {store} = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    store.checkAuth()
    if (!store.isAuth) {
      console.log("not authenticated at moneyflow")
      navigate('/login')
    }
  });

  useEffect(() => {
    const fetchMoneyFlowItems = async () => {
      try {
        const data = await MoneyFlowService.getMoneyFlowItems();
        setMoneyFlowItems(data.money_flow_items || []);
      } catch (error) {
        console.error('Error fetching money flow items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMoneyFlowItems();
  }, []);

  return (
    <div className="moneyflow__container">
      <h2 className="moneyflow__header">Движение средств</h2>
      <div className="background-circle"/>
      {isLoading ? (
        <div className="lds-ring__container">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="moneyflow__list-wrapper">
          {moneyFlowItems.length > 0 ? (
            moneyFlowItems.map((item, index) => {
              const hasRequiredFieldsForDebit = item.date_added && item.amount && item.period;

              return (
                <div key={index} className="moneyflow__list-item">
                  {item.record_type === 'DEBIT' && hasRequiredFieldsForDebit ? (
                    <><p className="list__item-date">{new Date(item.date_added).toLocaleDateString()} Списание</p>
                      <p className="list__item-amount">
                        {Intl.NumberFormat(undefined, {
                          style: 'currency',
                          currency: 'RUB',
                        }).format(item.record_type === 'DEBIT' ? -parseFloat(item.amount) : parseFloat(item.amount))}
                      </p>
                      <p className="list__item-period">По отчету за {periodDecrypt(item.period)}.
                      </p>

                    </>
                  ) : (
                    <>
                     <p className="list__item-date">{new Date(item.date_added).toLocaleDateString()} Начисление</p>
                      <p className="list__item-amount">
                        {Intl.NumberFormat(undefined, {
                          style: 'currency',
                          currency: 'RUB',
                        }).format(item.record_type === 'DEBIT' ? -parseFloat(item.amount) : parseFloat(item.amount))}
                      </p>
                      
                    </>
                  )}
                  {item.description && (
                    <p className="list__item-description"><strong>Комментарий:</strong> {item.description}</p>
                  )}
                </div>
              );
            })
          ) : (
            <p className="no-reports">No money flow items available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MoneyFlow;
